 

import { Card, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Link } from "react-router-dom";
import BasicLayout from "./authentication/components/BasicLayout";
import Logo from "../assets/images/linkpod_logo.png";
import { useState } from "react";
import { useUserApi } from "../service/useUserApi";
import ResetPassword from "./ResetPassword";
import { useFormik } from "formik";
import * as yup from "yup";
import Toast from "../components/Toast";
function ForgotPassword() {
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [email, setEmail] = useState("");
  const [toast, setToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastType, setToastType] = useState("success");
  const [otp, setOtp] = useState("");
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [loading, setLoading] = useState("");
  const { forgotPassword, verifyOtp } = useUserApi(setLoading);

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: yup.object({
        email: yup.string().required("Email is required"),
      }),
      onSubmit: (values) => {
        handleSendOtp();
      },
    });

  const formikOtp = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: yup.object({
      otp: yup.string().required("OTP is required"),
    }),
    onSubmit: (values) => {
      handleVerifyOtp();
    },
  });
  const handleSendOtp = async () => {
    const res = await forgotPassword({ email: values.email });
    setIsOtpSent(res?.status);
  };

  const handleVerifyOtp = async () => {
    const res = await verifyOtp({
      otp: formikOtp.values.otp,
      email: values.email,
    });
    if (!res?.status) {
      document.cookie = `token=${res?.token}`;
      setIsOtpVerified(true);
    }
  };

  return (
    <BasicLayout>
      {!isOtpVerified ? (
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center"
          >
            <img
              src={Logo}
              width={"100px"}
              style={{ borderRadius: "10px" }}
              alt="Logo"
            />
            <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
              Reset Password
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              You will receive an e-mail in maximum 60 seconds
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form">
              {!isOtpSent ? (
                <MDBox mb={4}>
                  <MDInput
                    type="email"
                    label="Email"
                    name="email"
                    variant="standard"
                    fullWidth
                    helperText={touched.email && errors.email}
                    error={!!(touched.email && errors.email)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                </MDBox>
              ) : (
                <MDBox mb={4}>
                  <MDInput
                    type="number"
                    label="Enter OTP"
                    variant="standard"
                    name="otp"
                    fullWidth
                    helperText={formikOtp.touched.otp && formikOtp.errors.otp}
                    error={!!(formikOtp.touched.otp && formikOtp.errors.otp)}
                    onChange={formikOtp.handleChange}
                    onBlur={formikOtp.handleBlur}
                    value={formikOtp.values.email}
                  />
                </MDBox>
              )}
              {!isOtpSent ? (
                <MDBox mt={6} mb={1}>
                  <MDButton
                    onClick={handleSubmit}
                    variant="gradient"
                    color="info"
                    fullWidth
                  >
                    {loading ? (
                      <CircularProgress color="light" size={"20px"} />
                    ) : (
                      "send otp"
                    )}
                  </MDButton>
                </MDBox>
              ) : (
                <MDBox mt={6} mb={1}>
                  <MDButton
                    onClick={handleVerifyOtp}
                    variant="gradient"
                    color="info"
                    fullWidth
                  >
                    {loading ? (
                      <CircularProgress color="light" size={"20px"} />
                    ) : (
                      "verify otp"
                    )}
                  </MDButton>
                </MDBox>
              )}
              <MDBox mt={1} mb={1}>
                <MDButton
                  component={Link}
                  to={"/sign-in"}
                  variant="outlined"
                  color="info"
                  fullWidth
                >
                  cancel
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      ) : (
        <ResetPassword email={values.email} setToast={setToast} setToastMsg={setToastMsg} setToastType={setToastType}/>
      )}
      {toast && <Toast toastMsg={toastMsg} toastType={toastType} />}
    </BasicLayout>
  );
}

export default ForgotPassword;
