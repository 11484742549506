import apiHandler from "./api-handler";

export const useDashboardApi = (setLoading) => {
  const { GET, POST } = apiHandler();

  const getUserData = async (userData) => {
    // setLoading(true);
    try {
      return await GET("api/user/getdata", userData);
    } catch (error) {
      console.error(error);
      throw error; // Re-throw the error so that the caller can handle it if needed
    } finally {
      // setLoading(false);
    }
  };

  return {
    getUserData,
  };
};