import React from 'react'
import {Box,Icon} from "@mui/material"
import MDAlert from './MDAlert'
import MDTypography from './MDTypography'

function Toast({toastType, toastMsg}) {
  let icon = toastType === "success" ? "check_circle":"dangerous" ;
  let color =  toastType === "success" ? "success":"error" ;
  return (

      <Box sx={{ position: "absolute", top: "2%", right: "2%" }}>
          <MDAlert color={color} dismissible>
            <Icon>{icon}</Icon>
            <MDTypography marginLeft={"10px"} variant="body2" color="white">
              {toastMsg}
            </MDTypography>
          </MDAlert>
        </Box>

  )
}

export default Toast
