 

import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CircularProgress, Box, Icon, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useUserApi } from "../service/useUserApi";
import Toast from "../components/Toast";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Logo from "../assets/images/linkpod_logo.png";
function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastType, setToastType] = useState("success");
  const { signIn } = useUserApi(setLoading);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const [userData, setUserData] = useState({ email: "", password: "" });
  const navigate = useNavigate();
  const cookies = document.cookie?.split(";").reduce((acc, cookie) => {
    const [name, value] = cookie.trim().split("=");
    acc[name] = value;
    return acc;
  }, {});
  useEffect(() => {
    if (cookies.isLogin === "true") {
      navigate("/dashboard");
    }
  }, []);

  const HandleSignIn = async (event) => {
    event.preventDefault();
    let res = await signIn(userData);
    if (res?.status === true) {
      setToast(true);
      setToastType("success");
      setToastMsg(res?.message);
      document.cookie = `token=${res?.token}`;
      document.cookie = `isLogin=${res?.isLogin}`;
      setTimeout(() => {
        navigate("/dashboard", { replace: false });
        setToast(false);
      }, 2000);
    } else {
      setToast(true);
      setToastType("error");
      setToastMsg(res?.message || "error");
      setTimeout(() => {
        setToast(false);
      }, 2000);
    }
  };

  return (
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <img
            src={Logo}
            width={"100px"}
            style={{ borderRadius: "10px" }}
            alt="Logo"
          />
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>

        <MDBox pt={6} pb={3} px={3}>
          <MDBox onSubmit={HandleSignIn} component="form" role="form">
            <MDBox mb={4}>
              <MDInput
                type="email"
                onChange={(e) =>
                  setUserData({ ...userData, email: e.target.value })
                }
                label="Email"
                fullWidth
              />
            </MDBox>
            <MDBox mb={4} position={"relative"}>
              <MDInput
                type={showPassword ? "text" : "password"}
                onChange={(e) =>
                  setUserData({ ...userData, password: e.target.value })
                }
                label="Password"
                fullWidth
              />
              {showPassword ? (
                <VisibilityIcon
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "13px",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <VisibilityOffIcon
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "13px",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              {/* <Switch checked={rememberMe} onChange={handleSetRememberMe} /> */}

              {/* <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography> */}
              <MDTypography
                component={Link}
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
                to="/forgot-password"
              >
                &nbsp;&nbsp;&nbsp;&nbsp;Forgot Password ?
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                {loading ? (
                  <CircularProgress color="light" size={"20px"} />
                ) : (
                  "sign in"
                )}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      {toast && <Toast toastMsg={toastMsg} toastType={toastType} />}
    </BasicLayout>
  );
}

export default Basic;
