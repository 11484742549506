import axios from "axios";
import Grid from '@mui/material/Grid'

const apiHandler = () => {
    const cookies = document.cookie?.split(";").reduce((acc, cookie) => {
        const [name, value] = cookie.trim().split("=");
        acc[name] = value;
        return acc;
    }, {});

    const CONNECT = async () => {
        console.log("making connection ...")
        return await axios.get(process.env.REACT_APP_SERVER_URL);
    }

    const GET = async (url) => {
        let data = await axios.get(process.env.REACT_APP_SERVER_URL + url, { headers: { "Authorization": "Bearer " + cookies?.token } });
        return data.data;
    }

    const POST = async (url, payload) => {
        let data = await axios.post(process.env.REACT_APP_SERVER_URL + url, payload, { headers: { "Authorization": "Bearer " + cookies?.token } });
        return data.data
    }

    const PATCH = async (url, payload) => {
        return await axios.patch(process.env.REACT_APP_SERVER_URL + url, payload, { headers: { "Authorization": "Bearer " + cookies?.token } });
    }

    const DELETE = async (url) => {
        return await axios.delete(process.env.REACT_APP_SERVER_URL + url, { headers: { "Authorization": "Bearer " + cookies?.token } });
    }

    return {
        GET,
        POST,
        PATCH,
        DELETE,
        CONNECT
    }
}

export default apiHandler;
