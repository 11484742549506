import { combineReducers } from "redux";
import User from "./user";
import Pods from "./pods"
import Posts from "./posts";

const rootReducer = combineReducers({
  User,
  Pods,
  Posts
});

export default rootReducer;
