import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDashboardApi } from "../service/useDashboardApi";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/reducer/user";
import { setPods } from "../redux/reducer/pods";
import { setPosts } from "../redux/reducer/posts";

const PrivateRoute = ({ children, setLoading }) => {
  const [data, setData] = useState(null);
  const { getUserData } = useDashboardApi();
  const dispatch = useDispatch();
  const user = useSelector((store)=>{
    return store.User;
  })

  const cookies = document.cookie?.split(";").reduce((acc, cookie) => {
    const [name, value] = cookie.trim().split("=");
    acc[name] = value;
    return acc;
  }, {});

  const handleGetData = async () => {
    if (cookies.isLogin === "true" && !user?.id) {
      setLoading(true)
      try {   
        await getUserData().then((res) => {
          
          if (res?.status) {
            dispatch(setUser(res?.data?.user));
            dispatch(
              setPods({
                joinedPods: res?.data?.joined_pod,
                myPods: res?.data?.my_pod,
              })
            );
            dispatch(
              setPosts({posts: res?.data?.posts?.data})
            );
          }
        });
      } catch (error) {
        console.log(error)
      }finally{
        setLoading(false);
      }
    }
  }

  useEffect(() => {
      handleGetData();
  }, [cookies.isLogin]);

  if (cookies.isLogin === "true") {
    return children;
  } else {
    return <Navigate to={"/sign-in"} />;
  }
};

export default PrivateRoute;
