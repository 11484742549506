import React from "react";

const GlobalLoader = () => {
  return (
    <div className="global-loader">
      {/* <span className="back">
        <span>L</span>
        <span>o</span>
        <span>a</span>
        <span>d</span>
        <span>i</span>
        <span>n</span>
        <span>g</span>
      </span> */}
      <span className="loader"></span>
    </div>
  );
};

export default GlobalLoader;
