 

// @mui material components
import Grid from "@mui/material/Grid";
import { Typography, Card, Icon, Box, CircularProgress } from "@mui/material";
import NoPostIcon from "../assets/images/folder.png";
import "../style.css";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import { useDispatch, useSelector } from "react-redux";
import PostCard from "../components/PostCard";
import { useEffect, useState } from "react";
import { useDashboardApi } from "service/useDashboardApi";
import { setPosts } from "../redux/reducer/posts";
import { usePostApi } from "../service/usePostApi";
import Toast from "components/Toast";
import Loader from "components/Loader";
import { globalColor } from "service/useCommonService";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const dispatch = useDispatch();
  const [toast, setToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastType, setToastType] = useState("success");
  const [loading, setLoading] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);
  const { name, email } = useSelector((store) => {
    return store.User;
  });
  const { getUserData } = useDashboardApi(setLoading);
  const { deletePost } = usePostApi();
  const { posts } = useSelector((store) => {
    return store.Posts;
  });

  const handleGetData = async () => {
    setLoading(true);
    try {
      await getUserData().then((res) => {
        if (res?.status) {
          setLoading(false);
          dispatch(
            setPosts({
              posts: res?.data?.posts?.data,
            })
          );
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePost = async (postId) => {
    const res = await deletePost(postId);
    if (res?.data?.status === true) {
      setToast(true);
      setToastType("success");
      setToastMsg(res?.data?.message);
      handleGetData();
    } else {
      setToast(true);
      setToastType("error");
      setToastMsg(res?.data?.message || "error");
    }
    setTimeout(() => {
      setToast(false);
    }, 2000);
  };

  useEffect(() => {
    if (posts.length) {
      let likes = 0;
      let comments = 0;
      posts.map((post) => {
        likes += post.likes;
        comments += post.comments;
      });
      setLikesCount(likes);
      setCommentsCount(comments);
    }
  }, [posts]);
  
  return (
    <DashboardLayout>
      <DashboardNavbar ab refresh={true} handleGetData={handleGetData} />
      <MDBox style={{ position: "relative" }} py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            {/* <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="visibility"
                title="Views"
                count={281}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "than lask week",
                }}
              />
            </MDBox> */}
            {/* <Card>
              <MDBox
                variant="gradient"
                // coloredShadow={"dark"}
                borderRadius="xl"
                display="flex"
                alignItems="center"
                width="100%"
                padding="15px"
                height="130px"
                gap="10px"
              >
                <img
                  style={{
                    width: "100px",
                    height: "100px",
                    border: "2px solid",
                    borderRadius: "10px",
                  }}
                  src={picture}
                  alt=""
                />
                <MDBox
                  display="flex"
                  flexDirection="column"
                  alignItems="start"
                  height="100%"
                >
                  <MDTypography variant="h3">{name}</MDTypography>
                  <MDTypography variant="body2">
                    {" "}
                    <strong>LinkPod Email :</strong> {email}
                  </MDTypography>
                  <MDTypography variant="body2">
                    <strong>LinkedIn Email :</strong> {linkedIn_email}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Card> */}
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="visibility"
                title="Views"
                count={likesCount * 7 || 0}
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="thumb_up"
                title="Likes"
                count={likesCount || 0}
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="textsms"
                title="Comments"
                count={commentsCount || 0}
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Connections"
                count="+91"
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid> */}
        </Grid>
        <Typography color={globalColor}>Latest Posts</Typography>
        <Box sx={{ minHeight: "50vh" }}>
          {loading ? (
            <Box
              height={"70vh"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Loader />
            </Box>
          ) : (
            <Grid container overflow={"auto"} gap={"15px"}>
              {posts && posts.length > 0 ? (
                [...posts.slice().reverse()].map((post, i) => {
                  return (
                    <PostCard
                      key={i}
                      post={post}
                      user={{ name, email }}
                      handleDeletePost={handleDeletePost}
                    />
                  );
                })
              ) : (
                <Card
                  style={{
                    width: "100%",
                    height: "calc(100vh - 20rem)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    bgcolor={"#fff"}
                    borderRadius={"10px"}
                    item
                    md={12}
                  >
                    <img width={"100px"} src={NoPostIcon} alt="no posts" />
                    <Typography variant="h3">No Posts Yet</Typography>
                  </Grid>
                </Card>
              )}
            </Grid>
          )}
        </Box>
        {/* <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>
      {toast && <Toast toastMsg={toastMsg} toastType={toastType} />}
    </DashboardLayout>
  );
}

export default Dashboard;
