import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  myPods: [],
  joinedPods: [],
  otherPods:[]
};

const podSlice = createSlice({
  name: "pods",
  initialState,
  reducers: {
    setPods: (state, action) => {
      const { myPods, joinedPods, otherPods } = action.payload;
      state.myPods = myPods || state.myPods;
      state.joinedPods = joinedPods || state.joinedPods;
      state.otherPods=otherPods || state.otherPods;
    },
  },
});

export const { setPods } = podSlice.actions;

export default podSlice.reducer;
