import { useState } from "react";
import apiHandler from "./api-handler";

export const usePostApi = () => {
  const { GET, POST, PATCH, DELETE } = apiHandler();
  const [pods, setPods] = useState([]);

  const createPost = async (postData) => {
    return POST("api/post/create", postData)
      .then((res) => {
        return res;
      })
      .catch((err) => console.error(err));
  };

  const deletePost = async (postId) => {
    return DELETE(`api/post/${postId}`)
      .then((res) => {
        return res;
      })
      .catch((err) => console.error(err));
  };

  const searchPost = async (value) => {
    return GET(`api/post/search?q=${value}`)
      .then((res) => {
        return res;
      })
      .catch((err) => console.error(err));
  };
  
  return {
    createPost,
    deletePost,
    searchPost,
  };
};
