 

// @mui material components
import {
  Grid,
  TextField,
  Button,
  Box,
  Modal,
  Typography,
  Card,
} from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import NoPostIcon from "../assets/images/folder.png";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useSelector, useDispatch } from "react-redux";
// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import PodCard from "../components/PodCard";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Model from "components/Model";
import { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { usePodApi } from "../service/usePodApi";
import { useDashboardApi } from "../service/useDashboardApi";
import Toast from "../components/Toast";
import { setPods } from "../redux/reducer/pods";
import Loader from "components/Loader";
import { globalColor } from "service/useCommonService";

function FindPods() {
  const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const [open, setOpen] = useState(false);
  const [myPodsList, setMyPodsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastType, setToastType] = useState("success");
  const [joinedPodsList, setJoinedPodsList] = useState([]);
  const [podData, setPodData] = useState({
    user_name: "cprathamesh82@gmail.com",
    pod_name: "",
    description: "",
  });
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState("");
  const {
    createPod,
    searchPod,
    pods,
    leavePod,
    joinPod,
    deletePod,
    getAllPod,
  } = usePodApi(setLoading);
  const { getUserData } = useDashboardApi(setLoading);
  const { joinedPods, myPods, otherPods } = useSelector((store) => {
    return store.Pods;
  });

  const handleGetData = async () => {
    setLoading(true);
    try {
      await getUserData().then((res) => {
        if (res?.status) {
          setLoading(false);
          dispatch(
            setPods({
              joinedPods: res?.data?.joined_pod,
              myPods: res?.data?.my_pod,
            })
          );
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetAllPods = async () => {
    setLoading(true);
    try {
      await getAllPod().then((res) => {
        if (res?.status) {
          setLoading(false);
          dispatch(
            setPods({
              otherPods: res?.data,
            })
          );
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreatePod = async (event) => {
    event.preventDefault();
    setPodData({ ...podData, pod_name: "", description: "" });
    setOpen(false);
    const res = await createPod(podData);
    if (res?.status === true) {
      setToast(true);
      setToastType("success");
      setToastMsg(res?.message);
      handleGetData();
      handleGetAllPods();
    } else {
      setToast(true);
      setToastType("error");
      setToastMsg(res?.message || "error");
    }
    setTimeout(() => {
      setToast(false);
    }, 2000);
  };

  const handleDeletePod = async (podId) => {
    const res = await deletePod(podId);
    if (res?.data?.status === true) {
      setToast(true);
      setToastType("success");
      setToastMsg(res?.data?.message);
      handleGetData();
      handleGetAllPods();
    } else {
      setToast(true);
      setToastType("error");
      setToastMsg(res?.data?.message || "error");
    }
    setTimeout(() => {
      setToast(false);
    }, 2000);
  };

  const handleJoinPod = async (podId) => {
    const res = await joinPod(podId);
    if (res?.status === true) {
      setToast(true);
      setToastType("success");
      setToastMsg(res?.message);
      handleGetData();
      handleGetAllPods();
    } else {
      setToast(true);
      setToastType("error");
      setToastMsg(res?.message || "error");
    }
    setTimeout(() => {
      setToast(false);
    }, 2000);
  };

  const handleLeavePod = async (podId) => {
    const res = await leavePod(podId);
    if (res?.data?.status === true) {
      setToast(true);
      setToastType("success");
      setToastMsg(res?.data?.message);
      handleGetData();
      handleGetAllPods();
    } else {
      setToast(true);
      setToastType("error");
      setToastMsg(res?.data?.message || "error");
    }
    setTimeout(() => {
      setToast(false);
    }, 2000);
  };

  const HandleSearchPod = (value) => {
    if (value === "") {
      setMyPodsList(myPods);
      setJoinedPodsList(joinedPods);
    } else {
      searchPod(value);
    }
  };

  useEffect(() => {
    setMyPodsList(myPods);
    setJoinedPodsList(joinedPods);
    handleGetAllPods();
  }, [myPods, joinedPods]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Authors Table
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white">
                    Projects Table
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns: pColumns, rows: pRows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox> */}
      {/* <Footer /> */}
      <Grid container spacing={2} mt={"20px"}>
        <Grid item xs={12} sm={12}>
          <TextField
            onChange={(e) => {
              setSearchData(e.target.value);
              HandleSearchPod(e.target.value);
            }}
            fullWidth
            label="Search pod by name or description"
          />
        </Grid>
      </Grid>
      <Box sx={{ minHeight: "80vh" }}>
        {loading ? (
          <Box
            height={"72vh"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            position={"relative"}
            mt={"30px"}
          >
            <Loader />
          </Box>
        ) : (
          <>
            {pods.length > 0 && searchData.length > 0 && (
              <Box mt={"15px"}>
                <Grid container spacing={2}>
                  {pods.map((pod) => (
                    <Grid item xs={12} md={12} lg={6}>
                      <MDBox mb={1.5}>
                        <PodCard
                          type={"join"}
                          handleLeavePod={handleLeavePod}
                          handleJoinPod={handleJoinPod}
                          pod={pod}
                        />
                      </MDBox>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
            {otherPods.length > 0 ? (
              <Box mt={"15px"}>
                <Typography color={globalColor}>Recommended Pods</Typography>
                <Grid container spacing={2}>
                  {otherPods.map((pod) => (
                    <Grid item xs={12} md={12} lg={6}>
                      <MDBox mb={1.5}>
                        <PodCard
                          type={"join"}
                          handleLeavePod={handleLeavePod}
                          handleJoinPod={handleJoinPod}
                          pod={pod}
                        />
                      </MDBox>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            ) : (
              <Card style={{ width: "100%", height: "calc(100vh - 14rem)", marginTop:"15px" }}>
                <Grid
                  bgcolor={"#fff"}
                  borderRadius={"10px"}
                  item
                  md={12}
                  minHeight={"65.5vh"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                >
                  <img width={"100px"} src={NoPostIcon} alt="no posts" />
                  <Typography variant="h3">No More Pods To Join</Typography>
                </Grid>
              </Card>
            )}
          </>
        )}
      </Box>
      {toast && <Toast toastMsg={toastMsg} toastType={toastType} />}
    </DashboardLayout>
  );
}

export default FindPods;
