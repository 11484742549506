import React, { useState } from "react";
import { Card, CircularProgress } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Logo from "../assets/images/linkpod_logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useUserApi } from "../service/useUserApi";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import * as yup from "yup";

const ResetPassword = ({ email, setToast, setToastType, setToastMsg }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { resetPassword } = useUserApi(setLoading);
  const navigate = useNavigate();
  const handleResetPassword = async () => {
    if (confirmPassword === password) {
      const res = await resetPassword({ new_password: values.password, email });
      if (res.status) {
        setToast(true);
        setToastType("success");
        setToastMsg("Password reset successfully");
        setTimeout(() => {
          setToast(false);
          navigate("/sign-in");
        }, 2000);
      } else {
        setToast(true);
        setToastType("error");
        setToastMsg("Something went wrong!");
      }
    }
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        password: "",
        confirmPassword: "",
      },
      validationSchema: yup.object({
        password: yup.string().required("Password is required"),
        confirmPassword: yup
          .string()
          .required("Confirm password is required")
          .oneOf([yup.ref("password"), null], "Confirm passwords must match"),
      }),
      onSubmit: (values) => {
        handleResetPassword();
      },
    });
  return (
    <Card>
      <MDBox
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="success"
        mx={2}
        mt={-3}
        py={2}
        mb={1}
        textAlign="center"
      >
        <img
          src={Logo}
          width={"100px"}
          style={{ borderRadius: "10px" }}
          alt="Logo"
        />
        <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
          Update Password
        </MDTypography>
        <MDTypography display="block" variant="button" color="white" my={1}>
          Enter you new password
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox component="form" role="form">
          <MDBox mb={4} position={"relative"}>
            <MDInput
              type={showPassword ? "text" : "password"}
              label="Enter Password"
              variant="standard"
              name="password"
              fullWidth
              helperText={touched.password && errors.password}
              error={!!(touched.password && errors.password)}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
            {showPassword ? (
              <VisibilityIcon
                style={{
                  position: "absolute",
                  right: "30px",
                  top: "13px",
                  cursor: "pointer",
                }}
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <VisibilityOffIcon
                style={{
                  position: "absolute",
                  right: "30px",
                  top: "13px",
                  cursor: "pointer",
                }}
                onClick={() => setShowPassword(!showPassword)}
              />
            )}
          </MDBox>
          <MDBox mb={4} position={"relative"}>
            <MDInput
              type={showConfirmPassword ? "text" : "password"}
              label="Enter Confirm Password"
              variant="standard"
              name="confirmPassword"
              fullWidth
              helperText={touched.confirmPassword && errors.confirmPassword}
              error={!!(touched.confirmPassword && errors.confirmPassword)}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmPassword}
            />
            {showConfirmPassword ? (
              <VisibilityIcon
                style={{
                  position: "absolute",
                  right: "30px",
                  top: "13px",
                  cursor: "pointer",
                }}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            ) : (
              <VisibilityOffIcon
                style={{
                  position: "absolute",
                  right: "30px",
                  top: "13px",
                  cursor: "pointer",
                }}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            )}
          </MDBox>
          <MDBox mt={6} mb={1}>
            <MDButton
              onClick={handleSubmit}
              variant="gradient"
              color="info"
              fullWidth
            >
              {loading ? (
                <CircularProgress color="light" size={"20px"} />
              ) : (
                "reset password"
              )}
            </MDButton>
          </MDBox>
          <MDBox mt={1} mb={1}>
            <MDButton
              component={Link}
              to={"/sign-in"}
              variant="outlined"
              color="info"
              fullWidth
            >
              cancel
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
};

export default ResetPassword;
