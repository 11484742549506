import { useState } from "react";
import apiHandler from "./api-handler";

export const usePodApi = (setLoading) => {
  const { GET, POST, PATCH, DELETE } = apiHandler();
  const [pods, setPods] = useState([]);

  const createPod = async (podData) => {
    return POST("api/pod/create", podData)
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  const getAllPod = async () => {
    return GET("api/pod/all")
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  const joinPod = async (podId) => {
    return POST(`api/pod/join/${podId}`)
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  const searchPod = async (search) => {
    setLoading(true);
    GET(`api/pod/search?query=${search}`)
      .then((res) => setPods(res?.data))
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const deletePod = async (podId) => {
     return DELETE(`api/pod/delete?pod_id=${podId}`)
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  const leavePod = async (podId) => {
    return PATCH(`api/pod/leave?pod_id=${podId}`)
      .then((res) => res)
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const editPod = async (podData) => {
    PATCH(`api/pod/edit`, podData)
      .then((res) => res)
      .catch((err) => console.error(err));
  };

  return {
    createPod,
    joinPod,
    searchPod,
    deletePod,
    leavePod,
    editPod,
    pods,
    getAllPod
  };
};
