import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import { Box } from "@mui/material";
import ComingSoonIcon from "../assets/images/coming-soon.png";
const ComingSoon = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"calc(100vh - 7.5rem)"}
      >
        <img width={150} src={ComingSoonIcon} alt="" />
      </Box>
    </DashboardLayout>
  );
};

export default ComingSoon;
