// import React from "react";
// import { Modal, Typography, Box, Icon } from "@mui/material";
import MDButton from "./MDButton";
import MDBox from "./MDBox";

// const Model = ({children, onClose, open}) => {
//   return (
//     <Modal
//       open={open}
//       onClose={() => {}}
//       style={{
//         position: "absolute",
//         top: "50%",
//         left: "25%",
//         width: "50%",
//         height: "50%",
//         border: "none",
//         borderRadius:"100px"
//       }}
//     >
//       <Box className="model-main">
//         <Box className="model-header">
//           <Typography variant="h4">Heading</Typography>

//           <Icon onClick={onClose}>close_icon</Icon>

//         </Box>

//         <Box bgcolor={"#fff"}>
//           {children}
//         </Box>

//         <MDBox className="model-footer">
//           <MDButton variant={"contained"} color="info">
//             Save
//           </MDButton>
//           <MDButton variant={"outlined"} color="info">
//             cancel
//           </MDButton>
//         </MDBox>
//       </Box>
//     </Modal>
//   );
// };

// export default Model;

import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { Paper, Typography, Box } from "@mui/material";

const DefaultHeader = ({ text }) => (
  <DialogTitle sx={{ m: 0, p: 2 }} id="draggable-dialog-title">
    {text}
  </DialogTitle>
);

const Model = ({
  children,
  open = false,
  header = false,
  headerText,
  footer,
  close = false,
  handleSubmit = () => {},
  handleClose = () => {},
  loading = false,
  resetForm = () => {},
}) => {
  React.useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        aria-labelledby="draggable-dialog-title"
      >
        <Box width={"100%"} className="modalform-header">
          {header && <DefaultHeader text={headerText} />}

          {close ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </Box>
        <form noValidate onSubmit={handleSubmit}>
          <DialogContent dividers>{children}</DialogContent>
          <DialogActions
            style={{ marginRight: "4%" }}
            className="modalform-footer"
          >
            {footer && (
              <div>
                <MDBox className="model-footer">
                  <MDButton type="submit" onClick={handleSubmit} variant="gradient" color="info">
                    Save
                  </MDButton>
                  <MDButton
                    onClick={handleClose}
                    variant={"outlined"}
                    color="info"
                  >
                    cancel
                  </MDButton>
                </MDBox>
              </div>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default Model;

Model.propTypes = {
  header: PropTypes.bool,
  headerText: PropTypes.string,
  footer: PropTypes.bool,
  close: PropTypes.bool,
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func,
};
