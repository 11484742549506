import apiHandler from "./api-handler";

export const useUserApi = (setLoading) => {
  const { GET, POST, PATCH } = apiHandler();
  const signUp = async (userData) => {
    setLoading(true);
    POST("api/user/create-account", userData)
      .then((res) => (window.location.href = `${res}`))
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const signIn = async (userData) => {
    setLoading(true);
    return POST("api/user/login", userData)
      .then((res) => res)
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const forgotPassword = async (userData) => {
    setLoading(true);
    return POST("api/user/forgot-password", userData)
      .then((res) => res)
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const resetPassword = async (userData) => {
    setLoading(true);
    return PATCH("api/user/reset-password", userData)
      .then((res) => res)
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const verifyOtp = async (userData) => {
    setLoading(true);
    return POST("api/user/verify-otp", userData)
      .then((res) => res)
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  const logout = async (userData) => {
    setLoading(true);
    document.cookie = "isLogin=false";
    return { status: true };
    // return GET("api/user/logout", userData)
    //   .then((res) => res)
    //   .catch((err) => console.error(err))
    //   .finally(()=>setLoading(false));
  };

  return {
    signUp,
    signIn,
    logout,
    forgotPassword,
    verifyOtp,
    resetPassword,
  };
};
