import React from "react";
import { Grid, Typography, Box, Icon,Tooltip } from "@mui/material";
import icon from "../assets/images/comment.png";
import members from "../assets/images/group.png";
import like from "../assets/images/like.png";
const PodCard = ({
  pod,
  handleLeavePod,
  type,
  handleJoinPod,
  handleDeletePod,
}) => {
  
  return (
    <Grid
      container
      className="pod-card"
      bgcolor={"#fff"}
      p={"5px 15px 15px 15px"}
      borderRadius={"10px"}
    >
      <Grid item xs={10}>
        <Grid item xs={12}>
          <Typography className="pod-name">{pod?.name}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box display={"flex"} alignItems={"center"} gap={"5px"}>
              <img width={"20px"} src={members} alt="" />
              <Typography fontSize={"15px"} variant="p">
                {pod?.member_count}
              </Typography>
              <img width={"20px"} src={like} alt="" />
              <img width={"20px"} src={icon} alt="" />
            </Box>
          </Box>
        </Grid>
        <Grid
          className="pod-description"
          fontSize={"15px"}
          xs={12}
          md={12}
          item
          mb={"-10px"}
          style={{ overflow: "hidden", width: "100%" }}
          title={pod?.description}
        >
          {pod?.description}
        </Grid>
      </Grid>

      <Grid item xs={12} md={2}>
        <Grid item xs={12} height={"100%"}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={["left", "left", "center"]}
            height={"100%"}
            mt={["10px", "10px", "5px"]}
          >
            {type === "join" ? (
              <button
                className="join-pod-btn"
                onClick={() => handleJoinPod(pod?._id)}
              >
                Join Pod
              </button>
            ) : type === "delete" ? (
              <Tooltip title={"Delete Post"} placement="top">
              <button
                className="leave-pod-btn"
                onClick={() => handleDeletePod(pod?._id)}
              >
                {/* <Icon fontSize="medium" sx={{ color: "rgb(249, 82, 82)" }}>
                  delete_icon
                </Icon> */}
                 <img width={"22px"} src="https://cdn-icons-png.flaticon.com/128/6096/6096937.png" alt="" />
              </button>
              </Tooltip>
            ) : (
              <Tooltip title={"Delete Post"} placement="top">
              <button
                className="leave-pod-btn"
                onClick={() => handleLeavePod(pod?._id)}
              >
              {/* <Icon fontSize="medium" sx={{ color: "rgb(249, 82, 82)" }}>
                  delete_icon
                </Icon> */}
                 <img width={"22px"} src="https://cdn-icons-png.flaticon.com/128/6096/6096937.png" alt="" />
              </button>
              </Tooltip>
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PodCard;
