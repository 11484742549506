import React, { useState } from "react";
import { Typography, Box, Tooltip, Grid, Icon } from "@mui/material";
import likeIcon from "../assets/images/like.png";
import commentIcon from "../assets/images/comment.png";
import { globalColor } from "service/useCommonService";

const PostCard = ({ post, user, handleDeletePost }) => {
  const [isEngagement, setIsEngagement] = useState(false);
  const postedTime = new Date(post?.createdAt);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = `${postedTime.getDate()}-${
    months[postedTime.getMonth()]
  }-${postedTime.getFullYear()}`;

  return (
    <Grid
      container
      lg={12}
      md={12}
      className="post-card"
      bgcolor={"#fff"}
      p={"5px 15px 15px 15px"}
      borderRadius={"10px"}
    >
      <Grid item xs={10}>
        <Grid item xs={12}>
          <div
            onClick={() => {
              window.open(post?.post_url, "_blank");
            }}
            className="post-title"
          >
            {post?.title}
          </div>
        </Grid>

        <Grid item xs={12}>
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"15px"}
            fontSize={"15px"}
          >
            <Typography fontSize={"16px"} gap={"5px"} color={"gray"}>
              <span style={{ margin: "5px" }}>
                <img
                  style={{ marginBottom: "-1px" }}
                  width={"18px"}
                  src={likeIcon}
                  alt="likes"
                />
              </span>
              {post?.likes}
              <span style={{ margin: "5px" }}>
                <img
                  style={{ marginBottom: "-1px" }}
                  width={"18px"}
                  src={commentIcon}
                  alt="comments"
                />
              </span>
              {post?.comments}
              <span style={{ margin: "5px", color:"gray" }}>
                added by {user?.name} on {date}, Engagement time : every 10-20
                seconds.
              </span>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
            color={"gray"}
            gap={"5px"}
            alignItems={"center"}
            fontSize={"16px"}
            variant="p"
          >
            You got{" "}
            <span style={{ margin: "0 5px" }}>
              <img width={"20px"} src={likeIcon} alt="likes" />
            </span>
            {post?.likes} likes on this boost
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={12} md={2}>
        <Grid item xs={12} height={"100%"}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={["left", "left", "center"]}
            height={"100%"}
            mt={["10px", "10px", "5px"]}
            className={"post-manage-btns"}
          >
            <Tooltip title={"Delete Post"} placement="top">
              <button
                className="leave-pod-btn"
                onClick={() => {
                  handleDeletePost(post._id);
                }}
              >
                {/* <Icon fontSize="medium" sx={{ color: "red" }}>
                  delete_icon
                </Icon> */}
                <img width={"22px"} src="https://cdn-icons-png.flaticon.com/128/6096/6096937.png" alt="" />
              </button>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PostCard;
