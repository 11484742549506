import {useNavigate} from "react-router-dom"
const useCommonService = () => {
    const router = useNavigate();

    const navigate =  (path, feature) => {
        router(path, feature)
    }

    function extractTitleFromURL(url) {
      const regex = /\/([^\/]+)-activity-\d+-/; // Updated regex to include "activity" and the numeric ID
      const matches = url.match(regex);
      
      if (matches && matches.length > 0) {
        const title = matches[matches.length - 1].replace(/[\s-_]+/g, ' '); // Replace spaces, hyphens, and underscores with a single space
        return title.trim();
      } else {
        return url;
      }
    }

    return {
      navigate,
      extractTitleFromURL
    }
};

export default useCommonService

export const globalColor = "rgb(48, 48, 48)"