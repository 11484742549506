import {
  Grid,
  TextField,
  Tooltip,
  Box,
  FormControl,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import NoPostIcon from "../assets/images/folder.png";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useSelector, useDispatch } from "react-redux";
import authorsTableData from "layouts/tables/data/authorsTableData";
import projectsTableData from "layouts/tables/data/projectsTableData";
import { useState } from "react";
import MDButton from "components/MDButton";
import PostCard from "../components/PostCard";
import "../style.css";
import { usePostApi } from "../service/usePostApi";
import { useDashboardApi } from "../service/useDashboardApi";
import { setPosts } from "../redux/reducer/posts";
import useCommonService from "../service/useCommonService";
import Toast from "components/Toast";
import Loader from "components/Loader";

function Posts() {
  const { columns, rows } = authorsTableData();
  const { columns: pColumns, rows: pRows } = projectsTableData();
  const { createPost, deletePost, searchPost } = usePostApi();
  const { extractTitleFromURL } = useCommonService();
  const [loading, setLoading] = useState(false);
  const [newPost, setNewPost] = useState(false);
  const [comments, setComments] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState("10000:20000");
  const [title, setTitle] = useState("");
  const [postUrl, setPostUrl] = useState("");
  const [searchedPostInput, setSearchPostInput] = useState("");
  const [searchedPost, setSearchPost] = useState(null);
  const [selectedPod, setSelectedPod] = useState(null);
  const [clickedCreate, setClickedCreate] = useState(false);
  const [toast, setToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastType, setToastType] = useState("success");
  const { getUserData } = useDashboardApi(setLoading);
  const dispatch = useDispatch();
  const { joinedPods, myPods } = useSelector((store) => {
    return store.Pods;
  });
  const { posts } = useSelector((store) => {
    return store.Posts;
  });

  const user = useSelector((store) => {
    return store.User;
  });

  const handleGetData = async () => {
    setLoading(true);
    try {
      await getUserData().then((res) => {
        if (res?.status) {
          setLoading(false);
          dispatch(
            setPosts({
              posts: res?.data?.posts?.data,
            })
          );
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreatePost = async (post) => {
    if (!postUrl || !selectedPod) {
      setClickedCreate(true);
      return;
    }

    setLoading(true);
    const res = await createPost({
      avgTime: selectedInterval,
      post_url: postUrl,
      pod_id: selectedPod,
      title: title,
      comments: comments,
    });
    if (res?.status === true) {
      setToast(true);
      setToastType("success");
      setToastMsg(res?.message);
      handleGetData();
      setNewPost(false);
    } else {
      setToast(true);
      setToastType("error");
      setToastMsg(res?.message || "error");
    }
    setTimeout(() => {
      setToast(false);
    }, 2000);
    setLoading(false);
  };

  let id = "";
  const handleSearchPost = async (value) => {
    if (value === "") {
      setSearchPost(null);
      return;
    }
    setLoading(true);
    if (id) {
    }

    setSearchPostInput(value);
    const res = await searchPost(value);
    if (res?.status === true) {
      setToast(true);
      setToastType("success");
      setToastMsg(res?.message);
      setSearchPost(res?.data);
    } else {
      setToast(true);
      setToastType("error");
      setToastMsg(res?.message || "error");
    }
    setTimeout(() => {
      setToast(false);
    }, 2000);
    setLoading(false);
  };

  const handleDeletePost = async (postId) => {
    const res = await deletePost(postId);
    if (res?.data?.status === true) {
      setToast(true);
      setToastType("success");
      setToastMsg(res?.data?.message);
      handleGetData();
    } else {
      setToast(true);
      setToastType("error");
      setToastMsg(res?.data?.message || "error");
    }
    setTimeout(() => {
      setToast(false);
    }, 2000);
  };

  const intervals = [
    { title: "10 - 20 seconds", value: "10000:20000" },
    { title: "20 - 40 seconds", value: "20000:40000" },
    { title: "40 - 60 seconds", value: "40000:60000" },
    { title: "1 - 2 minutes", value: "60000:120000" },
    { title: "2 - 3 minutes", value: "120000:180000" },
    { title: "3 - 4 minutes", value: "180000:240000" },
    { title: "4 - 5 minutes", value: "240000:300000" },
  ];

  const handleExtractTitle = (url) => {
    setPostUrl(url);
    const res = extractTitleFromURL(url);
    setTitle(res);
  };

  const handleComments = (value) => {
    if (value === "") {
      setComments([]);
      return;
    }
    const split = value.split("\n");
    setComments(split);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar refresh={true} handleGetData={handleGetData} />
      <Box sx={{ minHeight: "90vh" }}>
        {!newPost ? (
          <>
            <Grid container spacing={2} mt={"20px"}>
              <Grid item xs={8} sm={8} md={10} lg={10}>
                <TextField
                  onChange={(e) => {
                    handleSearchPost(e.target.value);
                  }}
                  fullWidth
                  label="Find Post"
                />
              </Grid>
              <Grid item xs={4} sm={4} md={2} lg={2}>
                <Box
                  display={"flex"}
                  justifyContent={"left"}
                  gap={"10px"}
                  alignItems={"center"}
                  mb={"25px"}
                >
                  {[...joinedPods, ...myPods].length > 0 ? (
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={() => setNewPost(true)}
                    >
                      New Post
                    </MDButton>
                  ) : (
                    <Tooltip
                      title="Please first join pod to create a post."
                      placement="top"
                    >
                      <MDButton variant="gradient" color="info">
                        New Post
                      </MDButton>
                    </Tooltip>
                  )}
                </Box>
              </Grid>
            </Grid>

            {loading ? (
              <Box
                height={"78vh"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                position={"relative"}
              >
                <Loader />
              </Box>
            ) : posts.length > 0 ? (
              <Box mt={"15px"}>
                {searchedPost ? (
                  <Grid container spacing={2}>
                    {searchedPost.length > 0 &&
                      [...searchedPost.slice().reverse()]?.map((post, i) => (
                        <Grid key={i} item xs={12} md={12} lg={12}>
                          <MDBox mb={1.5}>
                            <PostCard
                              post={post}
                              user={user}
                              handleDeletePost={handleDeletePost}
                            />
                          </MDBox>
                        </Grid>
                      ))}
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    {posts.length > 0 &&
                      [...posts.slice().reverse()]?.map((post, i) => (
                        <Grid key={i} item xs={12} md={12} lg={12}>
                          <MDBox mb={1.5}>
                            <PostCard
                              post={post}
                              user={user}
                              handleDeletePost={handleDeletePost}
                            />
                          </MDBox>
                        </Grid>
                      ))}
                  </Grid>
                )}
              </Box>
            ) : (
              <Card style={{ width: "100%", height: "calc(100vh - 14rem)" }}>
                <Grid
                  bgcolor={"#fff"}
                  borderRadius={"10px"}
                  item
                  md={12}
                  minHeight={"65.5vh"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                >
                  <img width={"100px"} src={NoPostIcon} alt="no posts" />
                  <Typography variant="h3">No Posts Yet</Typography>
                </Grid>
              </Card>
            )}
          </>
        ) : (
          <Box>
            <Grid container spacing={"20px"}>
              <Grid item xs={12} mb={"10px"}>
                <TextField
                  onChange={(e) => {
                    handleExtractTitle(e.target.value);
                  }}
                  fullWidth
                  label="Post Url"
                />
                {!postUrl && clickedCreate && (
                  <Typography color={"red"} fontSize={"15px"} variant="p">
                    Please paste a url to post
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography>Select the pods that you want to engage</Typography>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                  >
                    {myPods &&
                      joinedPods &&
                      [...joinedPods, ...myPods].map((pod) => {
                        return (
                          <FormControlLabel
                            value={pod?._id}
                            control={<Radio />}
                            label={pod?.name}
                            onChange={(e) => setSelectedPod(e.target.value)}
                          />
                        );
                      })}
                    {!selectedPod && clickedCreate && (
                      <Typography color={"red"} fontSize={"15px"} variant="p">
                        Please select a pod
                      </Typography>
                    )}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  Comments that will be used in your post engagement
                </Typography>
                <Typography variant="p" fontSize={"15px"}>
                  One line per comment, you can edit them here
                </Typography>
                <br />
                <TextField
                  onChange={(e) => {
                    handleComments(e.target.value);
                  }}
                  fullWidth
                  label="Manual Comments"
                  multiline
                  rows={10}
                />
                <br />
                <Typography variant="p" fontSize={"15px"}>
                  {comments.length} comments will be used on this post
                  engagement
                </Typography>
              </Grid>
              {/* <Grid item xs={6}>
              <Typography>Engage every</Typography>
              <Typography variant="p" fontSize={"15px"}>
                We use random interval engagement timing to make engagement
                boost looks more natural
              </Typography>
              <br />
              <Select
                style={{ padding: "13px" }}
                required
                fullWidth
                value={selectedInterval}
                onChange={(e) => {
                  setSelectedInterval(e.target.value);
                }}
              >
                {intervals.map((interval) => {
                  return (
                    <MenuItem key={interval.value} value={interval.value}>
                      Random interval between {interval.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid> */}
              <Grid display={"flex"} gap={"20px"} item xs={10}>
                <MDButton
                  variant="gradient"
                  onClick={handleCreatePost}
                  color={"info"}
                  disabled={loading}
                  style={{ width: "100px" }}
                >
                  {loading ? (
                    <CircularProgress color="light" size={"20px"} />
                  ) : (
                    "Create"
                  )}
                </MDButton>
                <MDButton
                  onClick={() => setNewPost(false)}
                  variant="outlined"
                  color="info"
                >
                  Cancel
                </MDButton>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      {toast && <Toast toastMsg={toastMsg} toastType={toastType} />}
    </DashboardLayout>
  );
}

export default Posts;
