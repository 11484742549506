 

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import { Card, CircularProgress } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import { useEffect, useState } from "react";
import { useUserApi } from "../service/useUserApi";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import BasicLayout from "./authentication/components/BasicLayout";
import Logo from "../assets/images/linkpod_logo.png";
function Cover() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { signUp, signIn, logout } = useUserApi(setLoading);
  const [userData, setUserData] = useState({ email: "", password: "" });
  const navigate = useNavigate();
  const cookies = document.cookie?.split(";").reduce((acc, cookie) => {
    const [name, value] = cookie.trim().split("=");
    acc[name] = value;
    return acc;
  }, {});

  useEffect(() => {
    if (cookies.isLogin === "true") {
      navigate("/dashboard");
    }
  }, []);

  const HandleSignUp = async (event) => {
    event.preventDefault();
    try {
      let res = await signUp(userData);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    // <CoverLayout image={bgImage}>
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
            <img
            src={Logo}
            width={"100px"}
            style={{ borderRadius: "10px" }}
            alt="Logo"
          />
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MDTypography>
        </MDBox>
        <MDBox pt={6} pb={3} px={3}>
          <MDBox onSubmit={HandleSignUp} component="form" role="form">
            <MDBox mb={4}>
              <MDInput
                required
                value={userData.email}
                type="email"
                onChange={(e) =>
                  setUserData({ ...userData, email: e.target.value })
                }
                label="Email"
                variant="standard"
                fullWidth
              />
            </MDBox>
            <MDBox mb={4} position={"relative"}>
              <MDInput
                required
                value={userData.password}
                type={showPassword ? "text" : "password"}
                onChange={(e) =>
                  setUserData({ ...userData, password: e.target.value })
                }
                label="Password"
                variant="standard"
                fullWidth
              />
              {showPassword ? (
                <VisibilityIcon
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "13px",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                />
              ) : (
                <VisibilityOffIcon
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "13px",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                />
              )}
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox required />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                {loading ? (
                  <CircularProgress color="light" size={"20px"} />
                ) : (
                  "sign Up"
                )}
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Already have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
