import Dashboard from "layouts/Dashboard";
import Pods from "layouts/Pods";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/Sign-In";
import SignUp from "layouts/Sign-Up";
import Icon from "@mui/material/Icon";
import LoginFailed from "layouts/LoginFailed";
import Posts from "layouts/Posts";
import FindPods from "layouts/FindPods";
import ComingSoon from "layouts/ComingSoon";
import ForgotPassword from "layouts/ForgotPassword";
import ResetPassword from "layouts/ResetPassword";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    private: true,
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Find Pods",
    private: true,
    key: "find-pods",
    icon: <Icon fontSize="small">search</Icon>,
    route: "/find-pods",
    component: <FindPods />,
  },
  {
    type: "collapse",
    name: "Pods",
    private: true,
    key: "pods",
    icon: <Icon fontSize="small">groups</Icon>,
    route: "/pods",
    component: <Pods />,
  },
  {
    type: "collapse",
    name: "Posts",
    private: true,
    key: "posts",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/posts",
    component: <Posts />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    private: true,
    icon: <Icon fontSize="small">account_circle</Icon>,
    route: "/profile",
    component: <ComingSoon />,
  },
  {
    type: "collapse",
    name: "Activity",
    key: "activity",
    private: true,
    icon: <Icon fontSize="small">local_activity</Icon>,
    route: "/activity",
    component: <ComingSoon />,
  },
  {
    type: "collapse",
    name: "LinkedIn Analytics",
    key: "linkedIn-analytics",
    private: true,
    icon: <Icon fontSize="small">bar_chart</Icon>,
    route: "/linkedIn-analytics",
    component: <ComingSoon />,
  },
  {
    type: "collapse",
    name: "Engage",
    key: "engage",
    private: true,
    icon: <Icon fontSize="small">spoke</Icon>,
    route: "/engage",
    component: <ComingSoon />,
  },
  {
    type: "collapse",
    name: "Notification",
    key: "notification",
    private: true,
    icon: <Icon fontSize="small">notifications_active</Icon>,
    route: "/notification",
    component: <ComingSoon />,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/sign-up",
    component: <SignUp />,
  },
  {
    // type: "collapse",
    name: "Login failed",
    key: "login-failed",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/forgot-password",
    component: <ForgotPassword />,
  },  
  // {
  //   type: "collapse",
  //   name: "Settings",
  //   key: "rtl",
  //   icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
  //   route: "/rtl",
  //   component: <RTL />,
  // },
  {
    type: "collapse",
    name: "Settings",
    key: "notifications",
    private: true,
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/settings",
    component: <Notifications />,
  },
  // {
  //   type: "collapse",
  //   name: "Profile",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  
];

export default routes;
